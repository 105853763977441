import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Software Engineer | Critical Thinker | Polymath | Arm Chair Philosopher | Fitness enthusiast | Aesthete | Bibliophile | geek | Stoic | Inquisitive | Student of Martial Arts, Yoga and Life`}</strong></p>
    </blockquote>
    <p>{`I'm a graduate student at Arizona State University, Tempe. I'm from a little city called Mysore. Programming, Reading, fitness, photography, philosophizing and critically thinking about religion, spirituality and philosophy are some of the things that I'm into.
Also someone who is interested in everything under the sun and beyond.`}</p>
    <br></br>
    <h4>{`My journey & skillset`}</h4>
    <div id="box-timeline">
    <div id="timeline" style={{
        "height": "220px"
      }}></div>
    </div>
    <div id="box-skills">
    <span id="languages" style={{
        "cssFloat": "left"
      }}></span>
    <span id="skills" style={{
        "cssFloat": "right"
      }}></span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      