import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="CementBin" link="https://github.com/aneeshmg/cement-bin" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A clone of Pastebin.com
    </ProjectCard>
    <ProjectCard title="Intelligent adaptive visualization of Yelp" link="https://github.com/aneeshmg/dv-project" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  An adaptive web application to visualize ratings, rating patterns and reviews of businesses on Yelp – using NLP, D3, NodeJS and MongoDB.
    </ProjectCard>
    <ProjectCard title="Document Clustering, summarization and Visualization" link="#" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A Python application to spatially allocate documents using Linear Dirichlet allocation and visualize this allocation using t-SNE algorithm.
    </ProjectCard>
    <ProjectCard title="Analysis of New York Taxi Geo-Spatial Data" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Identify Hot spots and Hot zones using distributed queries via Apache Spark running on a Hadoop cluster on Google Compute Platform.
    </ProjectCard>
    <ProjectCard title="Baray" link="https://github.com/aneeshmg/Baray" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  A light weight, high performance logging library for NodeJS applications.
    </ProjectCard>
    <ProjectCard title="Medical Named Entity Recognition and Assertion Extraction" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Identify and extract named entities from electronic medical records and perform assertions on them.
    </ProjectCard>
    <ProjectCard title="ASU Class Notifier" link="https://github.com/aneeshmg/ASUClassNotifier" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A chatbot to query and get information on open classes for registration, built using NodeJS on kik messenger platform deployed on Heroku.
    </ProjectCard>
    <ProjectCard title="Other projects" link="https://github.com/aneeshmg" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  View my other projects on github
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      