import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "aneesh.mg2007@gmail.com"
      }}>{`Hi`}</a>{` or find me on other platforms:`}</p>
    <a href="https://github.com/aneeshmg" target="_blank"><img height="64" width="64" src="/img/git.png" /></a>
    <a href="https://www.linkedin.com/in/aneeshmg/" style={{
      "marginLeft": "25px"
    }} target="_blank"><img height="64" width="64" src="/img/linkedin.png" /></a>
    <a href="https://www.facebook.com/Aneesh.MG" style={{
      "marginLeft": "25px"
    }} target="_blank"><img height="64" width="64" src="/img/facebook.png" /></a>
    <a href="https://twitter.com/mg_aneesh" style={{
      "marginLeft": "25px"
    }} target="_blank"><img height="64" width="64" src="/img/twitter.png" /></a>
    <a href="https://www.instagram.com/aneeshmg/" style={{
      "marginLeft": "25px"
    }} target="_blank"><img height="64" width="64" src="/img/instagram.png" /></a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      