import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
    <link href="/custom.css" rel="stylesheet"></link>
    <h1>{`Hi, I'm Aneesh`}<i className="material-icons play" id="aneesh">{`volume_up`}</i>{` Gangadhar`}<i className="material-icons play" id="gangadhar">{`volume_up`}</i></h1>
    <p>{`I `}<a parentName="p" {...{
        "href": "https://reads.aneeshmg.com"
      }}>{`read`}</a>{`. I `}<a parentName="p" {...{
        "href": "https://blog.aneeshmg.com"
      }}>{`write`}</a>{`. I transform code into `}<a parentName="p" {...{
        "href": "https://aneeshmg.github.io/Javascript"
      }}>{`cool things`}</a>{` among other things.`}</p>
    <span id="interesting" title="Show me something interesting">I'm bored</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      